import React from "react"
import { graphql, Link } from "gatsby"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"

import { AboutSvg } from "../svgs/svg"

import BackButton from "../components/backButton"
import Seo from "../components/seo"
import Localize from "../components/localize"

import { RightArrow } from "../shared/icons/icons"

const About = ({ data: { about, site }, pageContext }) => {
  const lan = pageContext.locale
  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "About us" : "Über uns"}
        description={
          lan === "en"
            ? "65 years of excellent advice – Our competent and experienced lawyers, notaries and experts advise and represent companies, investment companies and private individuals in all areas of commercial, civil and tax law."
            : "65 Jahre exzellente Beratung – Unsere kompetenten und erfahrenen Anwälte, Notare und Experten beraten und vertreten Unternehmen, Investmentgesellschaften und Privatpersonen in allen Bereichen des Wirtschafts-, Zivil- und Steuerrechts."
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "About Us: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Über uns: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />

      <BackButton
        to={`/${lan}/`}
        lan={lan}
        text={lan === "en" ? "Home" : "Startseite"}
      />
      <div className="header no--margin-menu header-typ2 about--us-header h-auto">
        <AboutSvg />
        <div
          id="headerText"
          className="headerText auto-adjust-header about--us-text about-text"
        >
          <h1 className="m-0">{about.about_title}</h1>
          {about.about_content.map(con => {
            return con.children.map((co, index) => {
              return (
                <p key={index}>
                  {lan === "en"
                    ? hyphenated(co.text)
                    : hyphenated(co.text, { language: de })}
                </p>
              )
            })
          })}
          <Link to={`/${lan}/contact/`} className="button contact">
            <div></div>
            <span className="icon-arrow_right">
              <RightArrow />
            </span>
            {lan && site.siteMetadata && site.siteMetadata[lan].contactUs}
          </Link>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    img: sanityStatic {
      siteImage {
        asset {
          url
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        en {
          contactUs
        }
        de {
          contactUs
        }
      }
    }
    about: sanityStatic {
      about_title {
        _type
        en
        de
      }
      about_content {
        _type
        de {
          children {
            text
          }
        }
        en {
          children {
            text
          }
        }
      }
    }
  }
`

export default Localize(About)
