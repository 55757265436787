import React from "react"
import { Link } from "gatsby"
import { LeftArrow } from "../shared/icons/icons"

const BackButton = props => {
  return (
    <div className={props.className} style={props.style} id="backbutton">
      <Link
        state={props.state ? props.state : null}
        to={props.to ? props.to : props.lan ? `/${props.lan}/` : "/de/"}
      >
        <div></div>
        <span className="icon-arrow_left">
          <LeftArrow />
        </span>
      </Link>
      <span>{props.lan === "en" ? "Back to" : "Zurück zu"}</span>
      <br />
      <p>{props.text}</p>
    </div>
  )
}

export default BackButton
